<template>
  <div class="flex flex-col h-dvh font-sans text-stone-950 bg-cloud dark:text-white dark:bg-stone-900">
    <div class="flex flex-col items-stretch py-12 gap-8">
      <h1 class="text-4xl font-semibold text-center">Dreamly</h1>
      <slot/>
    </div>
  </div>
</template>

<script setup>
</script>